import RcTableViewUtils from "@/portals/shared/table/RcTableViewUtils";

import BoxDisplayRow from "@/portals/shared/cell/box/BoxDisplayRow";
import RowDisplayRow from "@/portals/shared/cell/row/RowDisplayRow";
import StringDisplayRow from "@/portals/shared/cell//string/StringDisplayRow";
// import StringUpdateRow from "@/portals/shared/cell/string/StringUpdateRow.js";
import StringListUpdateRow from "@/portals/shared/cell/string/list/StringListUpdateRow.js";
import LabelValueDisplayRow from "@/portals/shared/cell//label/LabelValueDisplayRow";
import TitleDisplayRow from "@/portals/shared/cell/title/TitleDisplayRow";
import ButtonDisplayRow from "@/portals/shared/cell/button/ButtonDisplayRow";
import SpacerDisplayRow from "@/portals/shared/cell/spacer/SpacerDisplayRow";
// import StringUtils from '@/utils/StringUtils.js';
import StatusDisplayRow from "@/portals/shared/cell/status/StatusDisplayRow.js";
//import DateUpdateRow from "@/portals/shared/cell/date/DateUpdateRow.js";
import ImageDisplayRow from "@/portals/shared/cell/image/ImageDisplayRow";

import MC from "@/domain/session/MC.js";
import ContentUtils from '@/utils/ContentUtils.js';
import StringUtils from '@/utils/StringUtils.js';

import InspectionMap from "@/domain/model/inspection/InspectionMap";
import TimesheetMap  from '@/domain/model/timesheet/TimesheetMap.js';

export default class ListViewUtils extends RcTableViewUtils {

  constructor(model, tableData) {
    super(model, tableData);
    this.MC = new MC();
    this.pages = [];
    this.Strings = new StringUtils();
    this._inspectionMap = new InspectionMap(this.domain());
    this._timesheetMap = new TimesheetMap(this.domain());
  }

  start() {
    if (this.model().hasError()) {
      this.renderError();  
    }

    if (this.model().isStatePageLoading()) {
      super.start();
      return;
    }

    this.renderHeader(this.model().totalItems());

    if (this.model().isStateContentLoading()) {
      super.start();
      return;
    }

    if (this.model().isStateContentLoaded()) {
      this.renderBody();
    }

    this.renderFooter(this.model().totalItems());
  }

  renderBody() {
    var box = new BoxDisplayRow()
      .withBorder(true);
    box
      .withChild(new RowDisplayRow()
        .withBackgroundTitle()
        .withChild(new TitleDisplayRow().withAlign("centre").withWidth(15).withTitle("Compliance"))
      );

    var even = true;
    var list = this.model().itemList();
    var company = this.model().company();
    for (var index = 0; index < list.length; index++) {
      var employee = list[index];
      var user = employee.user().find();

      var nameRow = this.createNameRow(user, employee);

      var daysRow = this.createDaysRow();
      var timesheetsRow = this.createTimesheetsRow(employee, even);
      var inspectionsRow = this.createInspectionsRow(employee, even);
      
      var rows = new BoxDisplayRow().withEven(even)
                        .withChild(nameRow)
                        .withChild(daysRow)
                        ;
      if (company.hasTimesheets()) {
        rows.withChild(timesheetsRow)
      }
      if (company.hasInspections()) {
        rows.withChild(inspectionsRow)
      }
      box.withChild(rows);
      even = !even;
    }
  
    this.addBody(box);
  }

  renderFooter(totalItems) {
    var query = this.model().panel().$route.query;
    this.pages = this.model().pages();
    this.add(
        new BoxDisplayRow()
        .withBorder(true)
        .withWidth("15")
        .withChild(new RowDisplayRow()
          .withChild(new BoxDisplayRow()
            .withWidth("15")
            .withBorder(true)
            .withChild(new RowDisplayRow()
              .withChild(new LabelValueDisplayRow().withWidth("4").withLabel("Showing").withValue(this.createPageInfo(totalItems, query)))
              .withChild(new StringDisplayRow().withWidth("7"))
              .withChild(new ButtonDisplayRow().withWidth("1").withName("<").withField("PreviousPage"))
              .withChild(new StringListUpdateRow().withWidth("2")
                                                  .withShowLabel(false)
                                                  .withName("Jump to Page")
                                                  .withField("PageIndex")
                                                  .withDefaultValue(query["page"] ? query["page"] : "1")
                                                  .withOptions(this.pages))
              .withChild(new ButtonDisplayRow().withWidth("1").withName(">").withField("NextPage"))
            )
          )
        )
    );
  }

  renderHeader(totalItems) {
    var query = this.model().panel().$route.query;
    this.add(
      new RowDisplayRow()
      .withChild(
        new BoxDisplayRow()
        .withBorder(true)
        .withWidth("15")
        .withChild(new RowDisplayRow()
          .withChild(new LabelValueDisplayRow().withWidth("1").withLabel(""))
          .withChild(new SpacerDisplayRow().withWidth("12"))
          .withChild(new ButtonDisplayRow().withWidth("2").withName("Refresh").withField("Refresh"))
        )
        
        .withChild(new RowDisplayRow()
          .withChild(new BoxDisplayRow()
            .withWidth("15")
            .withBorder(true)
            .withChild(new RowDisplayRow()
              .withChild(new LabelValueDisplayRow().withWidth("4").withLabelWidth("5").withLabel("Showing").withValue(this.createPageInfo(totalItems, query)))
              .withChild(new StringDisplayRow().withWidth("4"))
              .withChild(new StringListUpdateRow().withWidth("3")
                                                  .withName("Items/Page")
                                                  .withField("PageSize")
                                                  .withShowLabel(false)
                                                  .withDefaultValue(this.model().pageSize(query))
                                                  .withOptions(ContentUtils.PAGE_SIZES()))
              .withChild(new ButtonDisplayRow().withWidth("1").withName("<").withField("PreviousPage"))
              .withChild(new StringListUpdateRow().withWidth("2")
                                                  .withShowLabel(false)
                                                  .withName("Jump to Page")
                                                  .withField("PageIndex")
                                                  .withDefaultValue(query["page"] ? query["page"] : "1")
                                                  .withOptions(ContentUtils.PAGE_INDEXES(totalItems, this.model().pageSize(query))))
              .withChild(new ButtonDisplayRow().withWidth("1").withName(">").withField("NextPage"))
            )
          )
        )
      )
    );
  }

  createDaysRow() {
    var daysRow = new RowDisplayRow();
    daysRow.withChild(new SpacerDisplayRow().withWidth(2))
    var daysBox = new BoxDisplayRow().withWidth(13);
    daysRow.withChild(daysBox)
    var daysRowBox = this.createDaysRowBox();
    daysBox.withChild(daysRowBox);
    return daysRow;
  }

  createDaysRowBox() {
    var daysBox = new BoxDisplayRow().withWidth(13);
    var daysRowBox = new RowDisplayRow();
    var day = this.domain().time().now().day();
    for (var dayIndex = 0; dayIndex < 15; dayIndex++) {
      daysRowBox.withChild(new StringDisplayRow().withFontSize("small").withValue(day.displayAs_MM_DD()).withWidth(1).withAlignLeft());
      day = day.previous();
    }
    daysBox.withChild(daysRowBox);
    return daysBox;
  }

  createTimesheetsRow(employee, even) {
    var timesheetsRow = new RowDisplayRow();
    timesheetsRow.withChild(new StringDisplayRow().withWidth(2).withValue("Hours of Service").withFontSize("small").withAlignLeft());
    var timesheetsBox = new BoxDisplayRow().withWidth(13);
    timesheetsRow.withChild(timesheetsBox)
    var timesheetsRowBox = this.createTimesheetsRowBox(employee, even);
    timesheetsBox.withChild(timesheetsRowBox)
    return timesheetsRow;
  }

  createTimesheetsRowBox(employee, even) {
    var timesheetsRowBox = new RowDisplayRow();
    var day = this.domain().time().now().day();
    for (var dayIndex = 0; dayIndex < 15; dayIndex++) {
      var found = this._timesheetMap.findForDay(day, employee);
      var imageRow = null;
      if (found.isEmpty()) {
        imageRow = new ImageDisplayRow()
          .withImageHosMissing();
      } else {
        imageRow = new ImageDisplayRow()
        .withImageHosCompleteDay();
      }
      var imageBox = new BoxDisplayRow().withWidth(1).withBorder(true).withEven(even).withChild(imageRow);
      timesheetsRowBox.withChild(imageBox);
      day = day.previous();
    }
    return timesheetsRowBox;
  }

  createInspectionsRow(employee, even) {
    var inspectionsRow = new RowDisplayRow();
    inspectionsRow.withChild(new StringDisplayRow().withWidth(2).withValue("Inspections").withFontSize("small").withAlignLeft());
    var inspectionsBox = new BoxDisplayRow().withWidth(13);
    inspectionsRow.withChild(inspectionsBox)
    var inspectionsRowBox = this.createInspectionsRowBox(employee, even);
    inspectionsBox.withChild(inspectionsRowBox)
    return inspectionsRow;
  }

  createInspectionsRowBox(employee, even) {
    var inspectionsRowBox = new RowDisplayRow();
    var day = this.domain().time().now().day();
    for (var dayIndex = 0; dayIndex < 15; dayIndex++) {
      var found = this._inspectionMap.findForDay(day, employee);
      var imagesRow = new BoxDisplayRow();
      if (found.isEmpty()) {
        imagesRow.withChild(new ImageDisplayRow()
          .withImageTransparent());
      } else {
        let sorted = found.sort();
        for (var sortIndex = 0; sortIndex < sorted.length; sortIndex++) {
          var inspection = sorted[sortIndex];
          if (inspection.isDefectTypeGood()) {
            imagesRow.withChild(new ImageDisplayRow()
              .withImageInspectionCompleteGood());
          }
          if (inspection.isDefectTypeMinor()) {
            imagesRow.withChild(new ImageDisplayRow()
              .withImageInspectionCompleteMinor());
          }
          if (inspection.isDefectTypeMajor()) {
            imagesRow.withChild(new ImageDisplayRow()
              .withImageInspectionCompleteMajor());
          }
        }
      }
      var imageBox = new BoxDisplayRow().withWidth(1).withBorder(true).withEven(even).withChild(imagesRow);
      inspectionsRowBox.withChild(imageBox);
      day = day.previous();
    }
    return inspectionsRowBox;
  }

  createNameRow(user, employee) {
    if (employee) {
      //
    }

    var nameRow = new RowDisplayRow();
    nameRow.withChild(new StringDisplayRow().withWidth(2)
                          .withValue(user.fullName()).withAlignLeft());
    var licenseDate = user.licenseExpiryDateDisplay();
    if (StringUtils.isEmpty(licenseDate)) {
      licenseDate = "<Missing>";
    }
    if (user.hasLicenseExpired()) {
      nameRow
        .withChild(new StatusDisplayRow().withShowLabel(true).withLabelWidth(7).withName("License Expiry Date").withValue("Expired").withObject(licenseDate).withHint("Expired").withWidth(5))
      ;
    } else if (user.willLicenseExpireInWeeks(4)) {
      nameRow
        .withChild(new StatusDisplayRow().withShowLabel(true).withLabelWidth(7).withName("License Expiry Date").withValue("ExpireSoon").withObject(licenseDate).withHint("Expiring Soon").withWidth(5))
      ;
    } else if (user.willLicenseExpireInWeeks(1)) {
      nameRow
        .withChild(new StatusDisplayRow().withShowLabel(true).withLabelWidth(7).withName("License Expiry Date").withValue("ExpireNext").withObject(licenseDate).withHint("Expiring this week").withWidth(5))
      ;
    } else {
      nameRow
        .withChild(new LabelValueDisplayRow().withShowLabel(true).withLabelWidth(7).withName("License Expiry Date").withValue(licenseDate).withWidth(5))
      ;
    }
    nameRow.withChild(new SpacerDisplayRow().withWidth(6));
    nameRow.withChild(new ButtonDisplayRow().withName("Hide").withWidth(1));
    nameRow.withChild(new ButtonDisplayRow().withName("Remind").withWidth(1));
    return nameRow;
  }
}